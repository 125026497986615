import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img11 from './images/profile1.png'
import './First.css'
const CarouselSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };

  return (


    <Slider {...settings} >
      <div>
        <div className='scrol-page-start'>
          <div className='content-slider-1'>
            <p >RDRTech has transformed the way we manage our projects. The real-time tracking feature is a game-changer, and the easy task assignment has streamlined our workflow. Highly recommended!

            </p>
            <div className='Author-slider'>-Mohan Raj <br />Bangalore</div>
          </div>
          <div className='profile-image-1'>
            <img src={img11} alt="" className='profile-slide-1' />
          </div>
        </div>
      </div>
      <div>
        <div className='scrol-page-start'>
          <div className='content-slider-1'>
            <p > RDRTech's platform is incredibly user-friendly. The task management and coordination features have improved our team's efficiency and communication. The detailed reports have provided us with valuable insights.</p>
            <div className='Author-slider'>-Pankaj <br />Hyderabad</div>
          </div>
          <div className='profile-image-1'>
            <img src={img11} alt="" className='profile-slide-1' />
          </div>
        </div>
      </div>
      <div>
        <div className='scrol-page-start'>
          <div className='content-slider-1'>
            <p > With RDRTech, we have a clear view of all ongoing projects and can easily manage tasks and deadlines. The customer support is outstanding, and the platform's functionality is top-notch. Highly recommend for any company looking to optimize their project management.</p>
            <div className='Author-slider'>-Mathew<br />Coimbatore</div>
          </div>
          <div className='profile-image-1'>
            <img src={img11} alt="" className='profile-slide-1' />
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default CarouselSlider;
