import { faBusinessTime, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import './services.css'
import { faSnapchat } from '@fortawesome/free-brands-svg-icons';
import proj from '../image/project.png'
import support from '../image/support.png'
import techsupport from '../image/tech-support.png'
import goal from '../image/goal.png'
function Services() {
    return (
        <div className='container col-lg-12 col-md-12 col-sm-12'>
            <div className='row' id='service'>
                <div className='text-center mt-5 rsi'>
                    <h1>
                        RDRTech Service Innovations                  </h1>
                </div>
                <div class="  row ">
                    <div className="col-lg-6 col-md-6"data-aos="flip-down" data-aos-duration="4000">
                        <div className="card">
                            <img src={proj} alt="" class="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">Project Management</h5>
                                <p className="card-text">Our project management services offer streamlined planning, resource optimization, risk mitigation, and clear communication, ensuring on-time, on-budget project delivery. Partner with us for hassle-free project success.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 " data-aos="flip-down"data-aos-duration="2000">

                        <div className="card " >
                            <img src={support} alt="" class="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">Coustomer Services</h5>
                                <p className="card-text">Our customer service solutions are designed to deliver unparalleled support, satisfaction, and loyalty. From prompt responses to personalized interactions, we're committed to ensuring our customers receive the attention they deserve, every time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6" data-aos="flip-down" data-aos-duration="5000">
                        <div className="card">
                            <img src={techsupport} alt="" class="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">Professional Approach </h5>
                                <p className="card-text">Experience a professional approach with our services, where meticulous attention to detail, clear communication, and expert execution are at the forefront. Trust us to deliver excellence in every aspect of our partnership.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6" data-aos="flip-down"data-aos-duration="3000">
                        <div className="card">
                            <img src={goal} alt="" class="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">Development services</h5>
                                <p className="card-text">Our development services are crafted to transform your ideas into tangible solutions. With expertise in diverse technologies and a commitment to innovation, we're dedicated to bringing your projects to life, on time and within budget.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Services;
