import React from 'react';
import './flow.css';
import img1 from '../../src/Home/images1/logordr.jpg';

function Chart() {
  return (
    <>
    <div id='bgcolor'>
    <h1 className=' report'>Review The Report</h1>
    <div className="timeline">
      <div className="contain left-contain">
        <img src={img1} alt="RDRTech"  />
        <div className="text-box">
          <div id='img-4'></div>
          <h3 className='h3-4'>Add Project</h3>
          <span className="left-contain-arrow"></span>
        </div>
      </div>
      <div className="contain right-contain">
        {/* <img src={img1} alt="Task Assignment" /> */}
        <div className="text-box">
          <div id='img-1'></div>
          <h3>Add Your Client</h3>
          <span className="right-contain-arrow"></span>
        </div>
      </div>
      <div className="contain left-contain">
        <img src={img1} alt="Progress Tracking" id='demo-l-1' />
        <div className="text-box">
          <div id='img-2'></div>
          <h3 className='h3-2'>Add Employee</h3>
          <span className="left-contain-arrow"></span>
        </div>
      </div>
      <div className="contain right-contain">
        {/* <img src={img1} alt="Reporting" /> */}
        <div className="text-box">
          <div id='img-3'></div>
          <h3 className='h3-3'>Track Progress</h3>
          <span className="right-contain-arrow"></span>
        </div>
      </div>
      <div className="contain left-contain">
        
        <img src={img1} alt="Progress Tracking" id='demo-l'/>  
        <div className="text-box">
          <div id='img-5'></div>
          <h3 className='h3-2'>Daily Update</h3>
          <span className="left-contain-arrow"></span>
        </div>
      </div>
      <div className="contain right-contain">
   
        <div className="text-box">
          <div id='img-6'></div>
          <h3 className='h3-3'>Feedback</h3>
          <span className="right-contain-arrow"></span>
        </div>
      </div>
    </div>
    </div>
  </>
  );
}

export default Chart;
